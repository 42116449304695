import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import twitter from '../Assets/Images/icons/twitter.png';
import telegram from '../Assets/Images/icons/telegram.png';
import medium from '../Assets/Images/icons/medium.png';
import cursorFollow from '../Assets/Images/icons/cursorFollow.png';
import reddit from '../Assets/Images/icons/reddit.png';
// import discord from '../Assets/Images/icons/discord.png';
// import facebook from '../Assets/Images/icons/facebook.png';
// import insta from '../Assets/Images/icons/insta.png';
// import youtube from '../Assets/Images/icons/youtube.png';
// import github from '../Assets/Images/icons/github.png';

const Footer = () => {
  return (
    <div>
      {/* footer section start */}
      <div className='communitySec mt-5' id='community'>
        <Container className='py-5'>
          <Row>
            <Col lg={6} className='mx-auto'>
              <div className='mainTitleDiv text-center mb-5'>
                <h2 className='wow animate__animated animate__flipInX' data-text='Our Community'>Our Community</h2>
                <p className='wow animate__animated animate__flipInX'>Together we can make the SolPaw community even stronger</p>
              </div>
            </Col>
          </Row>
          <div className='communityLinks mb-2'>
            <a href='https://twitter.com/SolPaw_meme' target='_blank' rel='noreferrer'><img src={twitter} alt='twitter' /></a>
            <a href='https://t.me/SolPaw_Official' target='_blank' rel='noreferrer'><img src={telegram} alt='telegram' /></a>
            <a href='https://medium.com/@SolPaw_meme' target='_blank' rel='noreferrer'><img src={medium} alt='medium' /></a>
            <a href='https://www.reddit.com/user/SolPaw_Meme' target='_blank' rel='noreferrer'><img src={reddit} alt='reddit' /></a>
            {/* <a href='#'><img src={reddit} alt='reddit' /></a> */}
            {/* <a href='#'><img src={discord} alt='discord' /></a>
            <a href='#'><img src={facebook} alt='facebook' /></a>
            <a href='#'><img src={insta} alt='insta' /></a>
            <a href='#'><img src={youtube} alt='youtube' /></a>
            <a href='#'><img src={github} alt='github' /></a> */}
          </div>
        </Container>
      </div>
      {/* footer section end */}
      {/* copyright section start */}
      <div className='copyrightSec py-4 text-center'>
        <Container>
          <p className='mb-0'>&copy; 2024 SolPaw. All rights reserved</p>
        </Container>
      </div>
      {/* copyright section end */}
      {/* <img src={cursorFollow} alt='' className='cursorFollowImg' id='cursorFollowImg' draggable="true" /> */}
    </div>
  )
}

export default Footer