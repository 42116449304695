import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ContextState from './Context/ContextState';
import Layout from './Components/Layout';
import Home from './Pages/Home';
import ROUTES from './Constants/Routes';
import Swap from './Pages/Swap';

function App() {

  // document.addEventListener('mousemove', (e) => {
  //   const cursorImg = document.querySelector('.doggy-container');
  //   cursorImg.style.left = `${e.pageX + 50}px`;
  //   cursorImg.style.top = `${e.pageY - 50}px`;
  // })

  return (
    <>
      <BrowserRouter>
        <ContextState>
          <Routes>
            <Route element={<Layout />}>
              <Route path={ROUTES.HOME} element={<Home />} />
              {/* <Route path={ROUTES.SWAP} element={<Swap />} /> */}
            </Route>
          </Routes>
        </ContextState>
      </BrowserRouter>
    </>
  );
}

export default App;
