import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import homeBannerImg from '../Assets/Images/homeBannerImg.png';
import bannerCoin1 from '../Assets/Images/bannerCoin1.png';
import solana1 from '../Assets/Images/solana1.png';
import solana2 from '../Assets/Images/solana2.png';
import solana4 from '../Assets/Images/solana4.png';
import bone from '../Assets/Images/bone.png';
import bone2 from '../Assets/Images/bone2.png';
import tokenomicsImg from '../Assets/Images/tokenomicsImg.png';
import supply from '../Assets/Images/icons/supply.png';
import banknote from '../Assets/Images/icons/banknote.png';
import drop from '../Assets/Images/icons/drop.png';
import marketing from '../Assets/Images/icons/marketing.png';
import roadmapImg from '../Assets/Images/roadmapImg.png';
// import aboutImg from '../Assets/Images/aboutImg.png';
import utility1 from '../Assets/Images/utility1.png';
import utility2 from '../Assets/Images/utility2.png';
import utility3 from '../Assets/Images/utility3.png';
import twitter from '../Assets/Images/icons/twitter.png';
import telegram from '../Assets/Images/icons/telegram.png';
import medium from '../Assets/Images/icons/medium.png';
import reddit from '../Assets/Images/icons/reddit.png';
import mexc from '../Assets/Images/mexc.png';
import raydium from '../Assets/Images/raydium.png';
import lbank from '../Assets/Images/lbank.png';
import polonex from '../Assets/Images/polonex.png';
import bitmart from '../Assets/Images/bitmart.png';
import bitget from '../Assets/Images/bitget.png';
import jupiter from '../Assets/Images/jupiter.png';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { CardBody, CardContainer, CardItem } from '../Components/Ui/3dCard';
import token1 from '../Assets/Images/token1.png';
import token2 from '../Assets/Images/token2.png';
import token3 from '../Assets/Images/token3.png';
import token4 from '../Assets/Images/token4.png';
import tokenNum from '../Assets/Images/icons/tokenNum.png';
import dextools from '../Assets/Images/dextools.png';
import dexscreen from '../Assets/Images/dexscreen.png';
import dexview from '../Assets/Images/dexview.png';
import coingecko from '../Assets/Images/coingecko.png';
import birdeye from '../Assets/Images/birdeye.png';
import buybtn from '../Assets/Images/icons/buybtn.png';

const Home = () => {
  const contractAddress = 'BipVCxPNRANbCoogpiuw8VUm3zE6vV2hSutFUNXXMt2R';
  const [copied, setCopied] = useState(false);
  const exchageItems = [mexc, raydium, lbank, polonex, bitmart, bitget, jupiter];
  // const liveItems = [dextools, dexscreen, dexview, coingecko, birdeye, dextools, dexscreen, dexview, coingecko, birdeye];
  const liveItems = [{ img: dextools, link: 'https://www.dextools.io/' }, { img: dexscreen, link: 'https://dexscreener.com/' }, { img: dexview, link: 'https://www.dexview.com/' }, { img: coingecko, link: 'https://www.coingecko.com/' }, { img: birdeye, link: 'https://birdeye.so/' }];
  // const [loading, setLoading] = useState(false);

  const options = {
    gap: '30px',
    type: 'loop',
    drag: 'free',
    perPage: 5,
    autoplay: true,
    interval: 0,
    speed: 25000,
    easing: 'linear',
    breakpoints: {
      1199: {
        perPage: 4,
      },
      991: {
        perPage: 3,
        speed: 20000,
      },
      575: {
        perPage: 2,
        speed: 15000,
      },
    },
    arrows: false,
    pagination: false,
    // easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    // easingFunc: t => 1 - Math.pow(1 - t, 3)
  };

  const options2 = {
    gap: '30px',
    type: 'loop',
    drag: 'free',
    perPage: 5,
    autoplay: true,
    interval: 0,
    speed: 35000,
    easing: 'linear',
    direction: 'rtl',
    breakpoints: {
      1199: {
        perPage: 4,
      },
      991: {
        perPage: 3,
        speed: 20000,
      },
      575: {
        perPage: 2,
        speed: 15000,
      },
    },
    arrows: false,
    pagination: false,
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const sections = document.getElementById('content-section');
  //     const rect = sections.getBoundingClientRect();
  //     console.log('rect', (rect.top <= window.innerHeight) && (rect.top >= 0));

  //     if ((rect.top <= window.innerHeight) && (rect.top >= 0)) {
  //       setLoading(true);
  //     }
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  return (
    <div>
      {/* banner section start */}
      <div className='homeBannerSec overflow-x-hidde overflow-y-visibl pt-3'>
        <Container fluid className='p-0 position-relative'>
          <Container>
            <div className='homeBannerTxt'>
              <h1 className='bannerTitle wow animate__animated animate__fadeInLeft'>Welcome to the world of <span>SolPaw</span></h1>
              <p className='bannerP mb-4 wow animate__animated animate__fadeInLeft'>The cutest and most playful meme token on the Solana blockchain! SolPaw is more than just a cryptocurrency. it's a community-driven project aimed at bringing joy, laughter, and financial opportunities to its holders.</p>
              <div className='wow animate__animated animate__fadeInLeft'>
                <a href='https://pinksale.finance/solana/launchpad/B4cc8iTzUHG6qiZ7vMmBW4CGdJ2KTTPkR6shZBSRDjmh' className='btn theme-btn bannerBtn' target='_blank' rel='noreferrer'><img src={buybtn} alt='' className='me-2' /> Buy Now</a>
              </div>
            </div>
          </Container>
          <div className='homeBannerImg'>
            <div className='bannerSocialBtns'>
              <a href='https://twitter.com/SolPaw_meme' target='_blank' rel='noreferrer'><img src={twitter} alt='twitter' /></a>
              <a href='https://t.me/SolPaw_Official' target='_blank' rel='noreferrer'><img src={telegram} alt='telegram' /></a>
              <a href='https://medium.com/@SolPaw_meme' target='_blank' rel='noreferrer'><img src={medium} alt='medium' /></a>
              <a href='https://www.reddit.com/user/SolPaw_Meme' target='_blank' rel='noreferrer'><img src={reddit} alt='reddit' /></a>
            </div>
            <img src={homeBannerImg} alt='bannerImg' className='bannerDog' />
            <img src={bannerCoin1} alt='bannerImg' className='coin1' />
            <img src={solana2} alt='bannerImg' className='coin2' />
            <img src={solana4} alt='bannerImg' className='coin3' />
          </div>
        </Container>
      </div>
      {/* banner section end */}

      {/* about section start */}
      <div className='aboutSec overflow-x-hidde overflow-y-visibl mt-md-5' id='about'>
        <Container className='position-relative z-2'>
          <Row className='justify-content-between'>
            <Col lg={7} xl={6}>
              <div className='mainTitleDiv text-center text-lg-start mb-5 wow animate__animated animate__fadeInLeft'>
                <h2 data-text='About SolPaw'>About SolPaw</h2>
                <p className='mt-4 mt-lg-5 mb-4'>SolPaw is a decentralized meme token built on the blazing-fast Solana blockchain. Inspired by our love for dogs and the excitement of the crypto world, SolPaw combines the best of both worlds to create a unique and entertaining experience for its holders.</p>
                <Button className='theme-btn aboutBtn'>Read More</Button>
              </div>
            </Col>
            <Col lg={5} xl={5} className='aboutCol'>
              {/* <img src={aboutImg} alt='aboutImg' className='d-block mx-auto' /> */}
              <div class="coin">
                <div class="coin__front"></div>
                <div class="coin__back"></div>
                <div class="coin__shadow"></div>
              </div>
            </Col>
          </Row>
        </Container>
        <img src={solana1} alt='' className='solana1' />
        <img src={solana2} alt='' className='solana2' />
        <img src={solana1} alt='' className='solana3' />
        <img src={solana4} alt='' className='solana4' />
        <img src={bone} alt='' className='bone1' />
        <img src={bone} alt='' className='bone2' />
        <img src={bone} alt='' className='bone3' />
        <img src={bone} alt='' className='bone4' />
        <img src={bone} alt='' className='bone5' />
      </div>
      {/* about section end */}

      {/* exchange section start */}
      <div className='exchangeSec overflow-x-hidde overflow-y-visibl pt-5'>
        <Container>
          <Row>
            <Col xl={10} xxl={8} className='mx-auto'>
              <div className='mainTitleDiv text-center'>
                <h2 className='wow animate__animated animate__flipInX' data-text='Future Exchange Lisitng'>Future Exchange Lisitng</h2>
              </div>
            </Col>
          </Row>
        </Container>

        <div className='exchangeBg py-4 my-4'>
          <Container className='exchangeCarousel'>
            <Splide options={options}>
              {exchageItems.map((item, index) => {
                return (
                  <SplideSlide key={index}>
                    <img src={item} alt='future exchange' />
                  </SplideSlide>
                )
              })}
            </Splide>
          </Container>
        </div>
      </div>
      {/* exchange section end */}

      {/* exchange section start */}
      <div className='exchangeSec overflow-x-hidde overflow-y-visibl pt-5'>
        <Container>
          <Row>
            <Col xl={10} xxl={8} className='mx-auto'>
              <div className='mainTitleDiv text-center'>
                <h2 className='wow animate__animated animate__flipInX' data-text='We will live on'>We will live on</h2>
              </div>
            </Col>
          </Row>
        </Container>

        <div className='exchangeBg py-4 my-4'>
          <Container className='exchangeCarousel'>
            <Splide options={options2}>
              {liveItems.map((item, index) => {
                return (
                  <SplideSlide key={index}>
                    <a href={item.link} target='_blank' className='d-block w-100'>
                      <img src={item.img} alt='' />
                    </a>
                  </SplideSlide>
                )
              })}
              {liveItems.map((item, index) => {
                return (
                  <SplideSlide key={index}>
                    <a href={item.link} target='_blank' className='d-block w-100'>
                      <img src={item.img} alt='' />
                    </a>
                  </SplideSlide>
                )
              })}
            </Splide>
          </Container>
        </div>
      </div>
      {/* exchange section end */}

      {/* tokenomics section start */}
      <div className='tokenomicsSec overflow-x-hidde overflow-y-visibl' id='tokenomics'>
        <Container>
          <Row>
            <Col xl={8} xxl={7} className='mx-auto'>
              <div className='mainTitleDiv text-center'>
                <h2 className='wow animate__animated animate__flipInX' data-text='Tokenomics'>Tokenomics</h2>
                <p className='wow animate__animated animate__flipInX'>Explore our Tokonomics to uncover the secrets behind SolPaw's innovative tokenomics and discover how it's reshaping the landscape of meme tokens on the Solana blockchain!</p>
                <div className='contractAddress py-2 px-sm-2 px-sm-4 mx-auto'>CONTRACT :<span className='mx-3 my-1 my-sm-0'>{contractAddress}</span>
                  <CopyToClipboard onCopy={() => { setCopied(true) }} text={contractAddress}>
                    <Button onBlur={() => { setCopied(false) }}>Copy</Button>
                  </CopyToClipboard>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className='tokenContainer position-relative pb-5'>
          {/* <div class={loading ? 'image-container img-animation' : 'image-container'} id='content-section'>
            <img src={bone2} alt="Bone Image" />
            <div class="color-overlay"></div>
          </div> */}
          <Container className='pt-5 pb-5'>
            <Row>
              <Col md={7} className='mb-3 mb-md-0'>
                <div className='tokenImgDiv wow animate__animated animate__pulse' data-wow-iteration='2'>
                  <CardContainer className='inter-var'>
                    <CardBody className=''>
                      <CardItem translateZ='100' className='w-full mt-4'>
                        <img src={tokenomicsImg} alt='tokenImg' className='d-block mx-auto' />
                      </CardItem>
                    </CardBody>
                  </CardContainer>
                </div>
              </Col>
              <Col md={5} className='align-self-center'>
                <div className='tokenTxtDiv'>
                  <ul>
                    <li className='wow animate__animated animate__fadeInRight' data-wow-delay='0.2s'><img src={banknote} alt='' />Presale + Liqidity <span>80%</span></li>
                    <li className='wow animate__animated animate__fadeInRight' data-wow-delay='0.3s'><img src={marketing} alt='' />Cex <span>10%</span></li>
                    <li className='wow animate__animated animate__fadeInRight' data-wow-delay='0.4s'><img src={drop} alt='' />Airdrop <span>5%</span></li>
                    <li className='wow animate__animated animate__fadeInRight' data-wow-delay='0.5s'><img src={marketing} alt='' />Team <span>5%</span></li>
                    {/* <li className='wow animate__animated animate__fadeInRight' data-wow-delay='0.1s'><img src={supply} alt='' />Team <span>5%</span></li> */}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
          <Container className='pt-5 pb-0 pb-lg-5'>
            <Row>
              <Col md={6} lg={3} className='mb-5 pb-4 wow animate__animated animate__fadeInUp'>
                <div className='tokenDiv px-4 pb-2'>
                  <div className='tokenImg'>
                    <img src={token1} alt='tokenomics' className='d-block mx-auto' />
                  </div>
                  <div className='tokenTxt text-uppercase text-center'>
                    <p className='mb-1'>taxes</p>
                    <h4>0/0</h4>
                    <p></p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3} className='mb-5 pb-4 wow animate__animated animate__fadeInUp'>
                <div className='tokenDiv px-4 pb-2'>
                  <div className='tokenImg'>
                    <img src={token2} alt='tokenomics' className='d-block mx-auto' />
                  </div>
                  <div className='tokenTxt text-uppercase text-center'>
                    <p className='mb-1'>MINT & FREEZE</p>
                    <h4>REVOKED</h4>
                    <p></p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3} className='mb-5 pb-4 wow animate__animated animate__fadeInUp'>
                <div className='tokenDiv px-4 pb-2'>
                  <div className='tokenImg'>
                    <img src={token3} alt='tokenomics' className='d-block mx-auto' />
                  </div>
                  <div className='tokenTxt text-uppercase text-center'>
                    <p className='mb-1'>LIQUIDITY</p>
                    <h4>BURNED</h4>
                    <p></p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3} className='mb-5 pb-4 wow animate__animated animate__fadeInUp'>
                <div className='tokenDiv px-4 pb-2'>
                  <div className='tokenImg'>
                    <img src={token4} alt='tokenomics' className='d-block mx-auto' />
                  </div>
                  <div className='tokenTxt text-uppercase text-center'>
                    <p className='mb-1'>TOTAL SUPPLY</p>
                    <h4>410,000,000</h4>
                    <p></p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      {/* tokenomics section end */}

      {/* buyToken section start */}
      <div className='buyTokenSec' id='buyToken'>
        <Container>
          <Row>
            <Col xl={10} xxl={8} className='mx-auto'>
              <div className='mainTitleDiv text-center'>
                <h2 className='wow animate__animated animate__flipInX' data-text='How To Buy? $SOLPAW'>How To Buy? $SOLPAW</h2>
              </div>
            </Col>
          </Row>

          <div className='buyTokenMain d-flex flex-wrap d-lg-block'>
            <div className='buyTokenDiv position-relative wow animate__animated animate__pulse'>
              <div className='tokenNumImg'>
                <img src={tokenNum} alt='' className='w-100' />
                <span>1</span>
              </div>
              <div className='buyTokenTxt'>
                <h4 className='fw-bold mb-1'>DOWNLOAD PHANTOM EXTENTION</h4>
                <p className='mb-0'>Download And Install Phantom On The Appstore or Alternatively If You're On Desktop Download And Install The Browser Extention.</p>
              </div>
            </div>
            <div className='buyTokenDiv position-relative wow animate__animated animate__pulse'>
              <div className='tokenNumImg'>
                <img src={tokenNum} alt='' className='w-100' />
                <span>2</span>
              </div>
              <div className='buyTokenTxt'>
                <h4 className='fw-bold mb-1'>SECURE YOUR SOL</h4>
                <p className='mb-0'>Now All You've Got To Do Is Buy Your SOL in The PHANTOM APP Or Alternatively You Can Use An Exchange And Deposit Using Your Wallet Address.</p>
              </div>
            </div>
            <div className='buyTokenDiv position-relative wow animate__animated animate__pulse'>
              <div className='tokenNumImg'>
                <img src={tokenNum} alt='' className='w-100' />
                <span>3</span>
              </div>
              <div className='buyTokenTxt'>
                <h4 className='fw-bold mb-1'>BUY SOME $SOLPAW</h4>
                <p className='mb-1'>Now Go To Raydium Or Jupiter And Paste The SolPaw Contract Address To Swap You SOL.</p>
                <div className='contractAddress py-2 px-sm-2 px-lg-4 mb-0'>CONTRACT :<span className='mx-1 my-1 my-sm-0'>{contractAddress}</span>
                  <CopyToClipboard onCopy={() => { setCopied(true) }} text={contractAddress}>
                    <Button onBlur={() => { setCopied(false) }}>Copy</Button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
            <div className='buyTokenDiv position-relative wow animate__animated animate__pulse'>
              <div className='tokenNumImg'>
                <img src={tokenNum} alt='' className='w-100' />
                <span>4</span>
              </div>
              <div className='buyTokenTxt'>
                <h4 className='fw-bold mb-1'>ADD TO YOUR WALLET</h4>
                <p className='mb-0'>Now You’re All Set! Welcome Aboard The Next Rocket To The Moon, Just Import The Contract Address To View You’re Holdings And You Can Track Your Earnings.</p>
              </div>
            </div>
          </div>
        </Container>
      </div>
      {/* buyToken section end */}

      {/* roadmap section start */}
      {/* <div className='roadmapSec overflow-x-hidde overflow-y-visibl position-relative' id='roadmap'>
        <Container>
          <Row>
            <Col xl={8} xxl={6} className='mx-auto'>
              <div className='mainTitleDiv text-center mb-5'>
                <h2 className='wow animate__animated animate__flipInX' data-text='Roadmap'>Roadmap</h2>
                <p className='wow animate__animated animate__flipInX'>Take a peek into our roadmap and see the exciting milestones ahead as we pave the way for SolPaw's journey to success on the Solana blockchain!</p>
              </div>
            </Col>
          </Row>

          <div className='roadmapImg d-none d-lg-block'>
            <img src={roadmapImg} alt='roadmapImg' className='w-100 mb-4' />
          </div>
          <Row>
            <Col sm={6} lg={3} className='roadCol mb-3 mb-lg-0'>
              <div className='roadmapDiv'>
                <div className='roadNum'>1</div>
                <div className='roadInfo wow animate__animated animate__fadeInUp'>
                  <h6 className='roadTitle'>Phase 1</h6>
                  <div className='roadTxt'>
                    <p>Launch Smart Contract</p>
                    <p>Global Marketing Campaigns</p>
                    <p>Airdrop</p>
                    <p>Partnership</p>
                    <p>CG & CMC Listing</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={3} className='roadCol mb-3 mb-lg-0'>
              <div className='roadmapDiv'>
                <div className='roadNum'>2</div>
                <div className='roadInfo wow animate__animated animate__fadeInUp'>
                  <h6 className='roadTitle'>Phase 2</h6>
                  <div className='roadTxt'>
                    <p>Token Staking And Farming</p>
                    <p>Tier 1 & CEX Listing</p>
                    <p>Trend On DexView , Dex Tools, Avai, Moontok</p>
                    <p>Launch PITGROK AI</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={3} className='roadCol mb-3 mb-lg-0'>
              <div className='roadmapDiv'>
                <div className='roadNum'>3</div>
                <div className='roadInfo wow animate__animated animate__fadeInUp'>
                  <h6 className='roadTitle'>Phase 3</h6>
                  <div className='roadTxt'>
                    <p>Tire 1 Top 20 CEC Lisitng</p>
                    <p>NNFT & PITxGROK ETH Stake</p>
                    <p>Global Partnership</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6} lg={3} className='roadCol mb-3 mb-lg-0'>
              <div className='roadmapDiv'>
                <div className='roadNum'>4</div>
                <div className='roadInfo wow animate__animated animate__fadeInUp'>
                  <h6 className='roadTitle'>Phase 4</h6>
                  <div className='roadTxt'>
                    <p>Futures Contract</p>
                    <p>Improve All Ecosystem</p>
                    <p>Solana Layer 2 Deployment </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <img src={solana4} alt='' className='solana1' />
        <img src={solana1} alt='' className='solana2' />
        <img src={solana4} alt='' className='solana3' />
        <img src={solana4} alt='' className='solana4' />
        <img src={bone} alt='' className='bone1' />
        <img src={bone} alt='' className='bone2' />
        <img src={bone} alt='' className='bone3' />
        <img src={bone} alt='' className='bone4' />
      </div> */}
      {/* roadmap section end */}

      {/* utility section start */}
      <div className='utilitySec overflow-x-hidde overflow-y-visibl pt-5 pb-sm-5' id='utility'>
        <Container className='mb-lg-5'>
          <Row>
            <Col xl={8} xxl={6} className='mx-auto'>
              <div className='mainTitleDiv text-center mb-lg-5'>
                <h2 className='wow animate__animated animate__flipInX' data-text='Our Utility'>Our Utility</h2>
              </div>
            </Col>
          </Row>

          <Row className='justify-content-around'>
            <Col md={6} lg={4} className='my-3 my-lg-0'>
              <div className='utilityDiv wow animate__animated animate__pulse' data-wow-iteration='2'>
                <div className='utilityImg'>
                  <img src={utility1} alt='utilityImg' />
                </div>
                <div className='utilityTxt'>
                  <h5>Farming</h5>
                  <p className='mb-0'>Stake your SolPaw tokens in our farming pools and earn rewards for your contribution to the ecosystem. Farming is a simple and rewarding way to participate in SolPaw and grow your token holdings.</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} className='my-3 my-lg-0'>
              <div className='utilityDiv wow animate__animated animate__pulse' data-wow-iteration='2'>
                <div className='utilityImg'>
                  <img src={utility2} alt='utilityImg' />
                </div>
                <div className='utilityTxt'>
                  <h5>Staking</h5>
                  <p className='mb-0'>Secure the SolPaw network and earn passive income by staking your tokens. Staking rewards incentivize holders to actively participate in maintaining the network's integrity and security.</p>
                </div>
              </div>
            </Col>
            <Col md={6} lg={4} className='my-3 my-lg-0'>
              <div className='utilityDiv wow animate__animated animate__pulse' data-wow-iteration='2'>
                <div className='utilityImg'>
                  <img src={utility3} alt='utilityImg' />
                </div>
                <div className='utilityTxt'>
                  <h5>NFT</h5>
                  <p className='mb-0'>Dive into the exciting world of non-fungible tokens (NFTs) with SolPaw. Explore unique and collectible NFTs featuring adorable SolPaw characters and unleash your creativity in the digital art space.</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* utility section end */}

    </div >
  )
}

export default Home