import React, { useEffect, useRef, useState } from 'react';
import { Container, Nav, Navbar } from 'react-bootstrap';
import logo from '../Assets/Images/logo.png';
import buybtn from '../Assets/Images/icons/buybtn.png';
import ROUTES from '../Constants/Routes';
import { Link } from 'react-router-dom';
import WOW from 'wowjs';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";

const Header = () => {

  const targetDate = new Date('2024-04-18T11:00:00');

  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      const utcDifference = new Date(difference);
      timeLeft = {
        days: Math.floor(utcDifference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((utcDifference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((utcDifference / (1000 * 60)) % 60),
        seconds: Math.floor((utcDifference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  };

  useEffect(() => {
    const wow = new WOW.WOW({
      boxClass: 'wow', // default
      animateClass: 'animate__animated', // default
      offset: 0, // default
      mobile: false, // default
      live: true, // default
    });
    wow.init();
  }, [])

  const controller = useRef();

  const onInitHandler = ({ conductor }) => {
    controller.current = conductor;
    // Trigger shoot when the controller is initialized
    controller.current.shoot();
  };

  // useEffect(() => {
  //   // Simulate initialization if it hasn't happened yet
  //   if (!controller.current) {
  //     controller.current = { shoot: () => console.log('Simulated shoot') };
  //     // You can replace the console.log with your actual shoot logic
  //     // Make sure to handle the case when controller.current is still undefined
  //   }
  // }, []);

  // const onInitHandler = ({ conductor }) => {
  //   controller.current = conductor;
  // };

  // const onShoot = () => {
  //   controller.current.shoot();
  // };

  // const onRun = () => {
  //   controller.current.run({ speed: 3 });
  // };

  // const onPause = () => {
  //   controller.current.pause();
  // };

  // const onStop = () => {
  //   controller.current.stop();
  // };

  return (
    <>
      <div className='stickyNav'>
        <div className='saleTimer pt-2 pb-2'>
          <Container className='d-flex flex-wrap align-items-center justify-content-center justify-content-sm-between gap-2 ps-md-5'>
            <div className='mx-md-auto'>
              {(timeLeft.days || timeLeft.hours || timeLeft.minutes || timeLeft.seconds) ?
                <span>PRESALE STARTS IN : {timeLeft.days}D : {formatTime(timeLeft.hours)}H : {formatTime(timeLeft.minutes)}M</span>
                :
                <span style={{ wordSpacing: '3px' }}> SALE STARTED NOW!</span>
              }
            </div>
            <a href='https://pinksale.finance/solana/launchpad/B4cc8iTzUHG6qiZ7vMmBW4CGdJ2KTTPkR6shZBSRDjmh' className='headerBtn buyBtn' target='_blank' rel='noreferrer'><img src={buybtn} alt='' className='me-2' /> Buy Now</a>
          </Container>
        </div>
        <Fireworks onInit={onInitHandler} />
        <Navbar expand='lg' className='mainNavbar py-3'>
          <Container className='justify-content-betwee'>
            <Navbar.Brand>
              <Link to={ROUTES.HOME} >
                <img src={logo} alt='logo' className='headerLogo' />
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='navbarScroll' />
            <Navbar.Collapse id='navbarScroll'>
              <Nav className='mx-auto	align-items-center'>
                <Nav.Link href='#about'>About Us</Nav.Link>
                <Nav.Link href='#tokenomics'>Tokenomics</Nav.Link>
                <Nav.Link href='#buyToken'>How To Buy?</Nav.Link>
                <Nav.Link href='#utility'>Our Utility</Nav.Link>
                <Nav.Link href='#community'>Community</Nav.Link>
              </Nav>

              {/* <a href='https://pinksale.finance/solana/launchpad/B4cc8iTzUHG6qiZ7vMmBW4CGdJ2KTTPkR6shZBSRDjmh' className='headerBtn buyBtn mx-auto mx-lg-2' target='_blank' rel='noreferrer'><img src={buybtn} alt='' className='me-2' /> Buy Now</a> */}
              <a href={'./SolPaw_WhitePaper.pdf'} className='headerBtn mx-auto mx-lg-2' target='_blank' rel='noreferrer'>White Paper</a>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  )
}

export default Header